// Body
$body-bg: #fff;

// Typography
$header-font: 'Gilroy', 'Khula', 'Helvetica', 'Arial', sans-serif;
$body-font: 'Gilroy', 'Khula', 'Helvetica', 'Arial', sans-serif;
$hand-font: 'TrashHand', 'Covered By Your Grace', cursive;

// Colors
$primary: #007EC7;
$primary-light: rgba(0, 126, 199, 0.2);
$secondary: #FC579F;
$tertiary: #000F18;
$midgrey: #919191;
$grey: #cccccc;
$lightgrey: #ECECEC;

$twitter: #1da1f2;
$linkedin: #2867B2;
$instagram: #E1306C;

$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
